import { IndexSource } from '@/domain/entities/Product';
import { StepTypes as NewOnboardingStepTypes } from '@/presentation/components/NewOnboarding/consts';
import { CommentSortOptionsV2 } from '../entities/Comment';

/* eslint-disable camelcase */
export type ExtraInfo = MapExtraInfoByGeneralDealEvents &
  MapExtraInfoByFeedEvents &
  MapExtraInfoBySearchEvents &
  MapExtraInfoByGeneralProductEvents &
  MapExtraInfoByAlertEvents &
  MapExtraInfoByOnboardingEvents &
  MapExtraInfoByCommunityEvents &
  MapExtraInfoByBlogEvents &
  MapExtraInfoAlgoliaClickOutEvent;

export type AnalyticsEvent = 'analytics';

export const enum AlgoliaEvents {
  ClickOut = 'algolia_click_out',
}

export type EventName =
  | GeneralDealEvents
  | FeedEvents
  | SearchEvents
  | GeneralProductEvents
  | AlertEvents
  | OnboardingEvents
  | CommunityEvents
  | BlogEvents
  | AlgoliaEvents;

export const enum EventCategory {
  GeneralDeal = 'general_deal_events',
  Feed = 'feed',
  Search = 'search',
  GeneralProduct = 'general_product_events',
  Alert = 'wish_alerts',
  HotDeals = 'hot_deals',
  Onboarding = 'onboarding',
  Community = 'community',
  Blog = 'blog',
  Algolia = 'algolia',
}

export const enum GeneralDealEvents {
  ClickToGoToStorePage = 'click_to_go_to_store_page',
  ClickToGoToDealDetails = 'click_to_go_to_deal_details',
  ClickToViewComments = 'click_to_view_comments',
  ClickToCopyCouponCode = 'click_to_copy_coupon_code',
  ClickOnCommentTrigger = 'click_on_comment_trigger',
  ClickOnSortCommentOption = 'click_on_sort_comment_option',
  ClickInSuggestedComment = 'click_in_suggested_comment',
  ClickToShareDeal = 'click_to_share_deal',
  DealSave = 'deal_save',
  DealUnsave = 'deal_unsave',
  commentSubmit = 'comment_submit',
  DealCommentsScrollPagination = 'scroll_to_fetch_more_comments',
  ClickToViewDescription = 'click_to_view_description',
  ClickToSwipeImage = 'click_to_swipe_image',
  click_to_view_comparator = 'click_to_view_comparator',
}

export const enum FeedEvents {
  ViewDealCard = 'view_deal_card',
  ClickToSelectFeedMenu = 'click_to_select_feed_menu',
  ClickFixedCard = 'click_fixed_card',
}

export const enum SearchEvents {
  ClickToTypeASearchTerm = 'click_to_type_a_search_term',
  TryToSearchForATerm = 'try_to_search_for_a_term',
  ClickToSortSearchResults = 'click_to_sort_search_results',
  ClickToSelectASearchResultType = 'click_to_select_a_search_result_type',
  ClickOnSuggestedSearch = 'click_on_suggested_search',

  ViewProductsResults = 'view_products_results',
  ViewDealsResults = 'view_deals_results',
  ViewStoresResults = 'view_stores_results',
}

export const enum GeneralProductEvents {
  ClickToGoToProductDetails = 'click_to_go_to_product_details',
  ClickToGoToComparatorHome = 'click_to_go_to_comparator_home',
  ClickToShareProduct = 'click_to_share_product',
  ClickToShowPriceHistory = 'click_to_show_price_history',
  ClickOnProductTab = 'click_on_product_tab',
}

export const enum BlogEvents {
  ClickToGoToBlogPage = 'click_to_go_to_blog_page',
  ClickToGoToBlogPost = 'click_to_go_to_blog_post',
  ClickOnPostLink = 'click_on_post_link',
}

export const enum ProductComponentNames {
  Header = 1,
  BottomNavigation,
  ProductCardNoResultsSearch = 'product_card_no_results_search',
  SuggestedProductsOnSearchPage = 'suggested_products_on_search_page',
  ProductCardWithoutDealsOnProductPage = 'product_card_no_deal_on_product_page',
  RelatedProductsSuggestionsOnProductPage = 'related_products_suggestions_on_product_page',
  BlogList = 'blog_list',
  TopProductsByModelOnComparatorPage = 'top_products_by_model_on_comparator_page',
}

export const enum AlertsComponentNames {
  AlertSuggestionNoDealProductPage = 'alert_suggestion_no_deal_product_page',
  AlertSuggestionModalSearchPage = 'alert_suggestion_modal_product_page',
}

export const enum OnboardingEvents {
  OnboardWebStepVoting = 'onboard_web_step_voting',
  OnboardWebStepCategories = 'onboard_web_step_categories',
  OnboardWebStepVideo = 'onboard_web_step_video',
  OnboardWebVote = 'onboard_web_vote',
  OnboardWebStart = 'onboard_web_start',
  OnboardWebHome = 'onboard_web_go_home',
  OnboardWebEnd = 'onboard_web_end',
  OnboardWebSkipVideo = 'onboard_web_skip_video',
  OnboardWebCloseView = 'onboard_web_close_view',
}

export const enum CommunityEvents {
  ClickToGoBackToCommunities = 'click_to_go_to_communities',
}

export const enum CommentTriggerType {
  VOTE = 'vote',
}

export const enum SortCommentType {
  HELPFUL = 'helpful',
  NEWER = 'newer',
  OLDER = 'older',
}

export const commentSortOptionEventType: {
  [index in CommentSortOptionsV2]: SortCommentType;
} = {
  HELPFUL: SortCommentType.HELPFUL,
  NEWER: SortCommentType.NEWER,
  OLDER: SortCommentType.OLDER,
};

export const enum AlertEvents {
  ClickToCreateWishAlert = 'click_to_create_wish_alert',
  ModalSuggestionAlert = 'modal_suggestion_alert',
}

type MapEventsByCategory<Category extends EventCategory> = {
  [EventCategory.GeneralDeal]: GeneralDealEvents;
  [EventCategory.Feed]: FeedEvents;
  [EventCategory.Search]: SearchEvents;
  [EventCategory.GeneralProduct]: GeneralProductEvents;
  [EventCategory.Alert]: AlertEvents;
  [EventCategory.HotDeals]: GeneralDealEvents;
  [EventCategory.Onboarding]: OnboardingEvents;
  [EventCategory.Community]: CommunityEvents;
  [EventCategory.Blog]: BlogEvents;
  [EventCategory.Algolia]: AlgoliaEvents;
}[Category];

type MapExtraInfoByGeneralDealEvents = {
  [GeneralDealEvents.ClickToGoToStorePage]: {
    store: string;
    id?: string;
    index_item?: number;
  };
  [GeneralDealEvents.ClickToGoToDealDetails]: {
    component_name: string;
    recombeeActived: boolean;
    index_item?: number;
    deal_id?: string;
    came_from_dpl?: boolean;
  };
  [GeneralDealEvents.ClickToViewComments]: {
    web_temperature_top?: boolean;
  };
  [GeneralDealEvents.ClickToCopyCouponCode]: { component_name: string };
  [GeneralDealEvents.ClickOnCommentTrigger]: {
    triggerType: CommentTriggerType;
  };
  [GeneralDealEvents.ClickOnSortCommentOption]: {
    comment_sort_by: SortCommentType;
  };
  [GeneralDealEvents.ClickInSuggestedComment]: {
    id_suggestion_comment: string;
    web_clickout_return?: boolean;
  };
  [GeneralDealEvents.ClickToShareDeal]: {
    deal_id?: string;
  };
  [GeneralDealEvents.DealSave]: {
    component_source?: string;
    web_temperature_top?: boolean;
  };
  [GeneralDealEvents.DealUnsave]: {
    component_source: string;
  };
  [GeneralDealEvents.commentSubmit]: {
    isReply: boolean;
    displayedCommentTrigger: boolean;
    comment_sort_by: SortCommentType;
    is_suggested_comment: boolean;
  };
  [GeneralDealEvents.DealCommentsScrollPagination]: {
    deal_id?: string;
    scroll_number?: number;
    comment_sort_by?: CommentSortOptionsV2;
  };
  [GeneralDealEvents.ClickToViewDescription]: {
    component_source: string;
    offer_id?: string;
  };

  [GeneralDealEvents.ClickToSwipeImage]: {
    offer_id?: string;
  };

  [GeneralDealEvents.click_to_view_comparator]: undefined;
};

type MapExtraInfoByFeedEvents = {
  [FeedEvents.ViewDealCard]: { non_interaction: true };
  [FeedEvents.ClickToSelectFeedMenu]: {
    option: string;
    recombeeActived: boolean;
  };
  [FeedEvents.ClickFixedCard]: { interaction: true };
};

type MapExtraInfoByAlertEvents = {
  [AlertEvents.ClickToCreateWishAlert]: undefined;
  [AlertEvents.ModalSuggestionAlert]: undefined;
};

type MapExtraInfoByOnboardingEvents = {
  [OnboardingEvents.OnboardWebStepVoting]: {
    step: NewOnboardingStepTypes.VOTING_STEP;
    user_id: string;
  };

  [OnboardingEvents.OnboardWebStepCategories]: {
    step: NewOnboardingStepTypes.CATEGORIES_STEP;
    user_id: string;
  };
  [OnboardingEvents.OnboardWebStepVideo]: {
    step: NewOnboardingStepTypes.VIDEO_STEP;
    user_id: string;
  };
  [OnboardingEvents.OnboardWebVote]: {
    user_id: string;
  };
  [OnboardingEvents.OnboardWebStart]: {
    user_id: string;
  };
  [OnboardingEvents.OnboardWebHome]: {
    user_id: string;
  };
  [OnboardingEvents.OnboardWebEnd]: {
    user_id: string;
  };
  [OnboardingEvents.OnboardWebSkipVideo]: {
    user_id: string;
  };
  [OnboardingEvents.OnboardWebCloseView]: {
    user_id: string;
    step: string;
  };
};

type RangeKeys = 'from' | 'to';

export type FiltersUsedOnSearch = {
  [key in
    | `search_filter_${string}_${RangeKeys}`
    | `search_filter_${string}`]: Record<string, boolean>;
};

export type SearchSection = 'publications' | 'products' | 'stores';

type MapExtraInfoBySearchEvents = {
  [SearchEvents.ClickToTypeASearchTerm]: undefined;
  [SearchEvents.TryToSearchForATerm]: {
    search_term: string;
    search_result: boolean;
    result: boolean;
    failure_kind?: string;
    section?: SearchSection;
  } & FiltersUsedOnSearch;
  [SearchEvents.ClickToSortSearchResults]: {
    option: 'recent' | 'hottest' | 'last_commented' | 'relevance';
  };
  [SearchEvents.ClickToSelectASearchResultType]: {
    option: 'all' | 'publications' | 'products' | 'stores';
  };
  [SearchEvents.ClickOnSuggestedSearch]: {
    type: 'recents' | 'product' | 'store' | 'most_searched' | 'autocomplete';
    search_term?: string;
    selected_term?: string;
  };
  [SearchEvents.ViewDealsResults]: {
    deals_ids: string[];
  };
  [SearchEvents.ViewProductsResults]: {
    products_ids: string[];
  };
  [SearchEvents.ViewStoresResults]: {
    stores_ids: string[];
  };
};

export const enum ProductTab {
  PRICES = 'prices',
  TECHSPECS = 'techspecs',
  REVIEWS = 'reviews',
}

type MapExtraInfoByGeneralProductEvents = {
  [GeneralProductEvents.ClickToGoToProductDetails]: {
    index_item?: number;
    category_id?: string;
    index_source?: IndexSource;
    product_id?: string;
    expired_deal?: 'true' | 'false';
    came_from_dpl?: boolean;
    component_name?: ProductComponentNames;
  };
  [GeneralProductEvents.ClickToShareProduct]: undefined;
  [GeneralProductEvents.ClickToShowPriceHistory]: {
    product_id: string;
    product_name: string;
  };
  [GeneralProductEvents.ClickOnProductTab]: {
    tab: ProductTab;
  };
  [GeneralProductEvents.ClickToGoToComparatorHome]: {
    component_source: ProductComponentNames;
  };
};

type MapExtraInfoByCommunityEvents = {
  [CommunityEvents.ClickToGoBackToCommunities]: { interaction: true };
};

type MapExtraInfoByBlogEvents = {
  [BlogEvents.ClickToGoToBlogPage]: undefined;
  [BlogEvents.ClickToGoToBlogPost]: {
    slug: string;
    category: string;
  };
  [BlogEvents.ClickOnPostLink]: undefined;
};

type MapExtraInfoByEvents<
  Category extends EventCategory,
  Event extends MapEventsByCategory<Category>
> = ExtraInfo[Event];

export type GenericAnalyticsPayload = {
  category: EventCategory;
  name: EventName;
  skipAlgoliaEvent?: boolean;
  extra?: { [key: string]: unknown } | undefined;
};

export type MapExtraInfoAlgoliaClickOutEvent = {
  [AlgoliaEvents.ClickOut]: {
    product_id?: string;
    deal_id?: string;
  };
};

export type EmitTrackingEventParams<
  Category extends EventCategory,
  Name extends EventName,
  Extra extends { [key: string]: unknown } | undefined
> = {
  category: Category;
  name: Name;
  skipAlgoliaEvent?: boolean;
  extra: (Extra & { non_interaction?: boolean }) | undefined;
};

export type EmitTrackingEvent = <
  Category extends EventCategory,
  Event extends MapEventsByCategory<Category>
>(
  params: EmitTrackingEventParams<
    Category,
    Event,
    MapExtraInfoByEvents<Category, Event>
  >
) => void;
